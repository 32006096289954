<template>
  <TitleCtaContainer :cta :subtitle :title>
    <div
      class="bg-surface-default-hi rounded-lg flex flex-col md:flex-row"
      :class="showThumbnails ? 'md:h-[580px]' : 'md:h-400'"
    >
      <div class="md:shrink-0 md:grow-0 md:basis-380">
        <ImageRenderer
          v-if="image"
          v-bind="image"
          image-class="rounded-t-lg m-auto !h-200 w-full object-cover md:!h-full md:rounded-tr-none md:rounded-bl-lg"
        />
      </div>

      <div
        class="flex grow flex-col justify-between gap-16 overflow-hidden p-16 md:gap-24 md:p-24"
      >
        <div
          v-if="showThumbnails"
          class="mx-auto flex max-w-full items-start gap-12 overflow-x-auto overflow-y-hidden"
          :class="styles.invisibleScrollBar"
        >
          <div
            v-for="(item, index) in carousels"
            :key="item.id"
            class="flex w-72 flex-col items-center justify-center gap-4 text-center"
          >
            <RevButtonBase
              class="bg-surface-default-low rounded-lg h-48 w-72 cursor-pointer border hover:border-static-default-hi"
              :class="
                currentCarouselIndex === index
                  ? 'border-static-default-hi'
                  : 'border-transparent'
              "
              data-test="recommendation-thumbnail"
              @click="() => onThumbnailClick(index)"
            >
              <ImageRenderer v-bind="item.thumbnail" image-class="rounded-lg" />
            </RevButtonBase>
            <div class="text-static-default-hi caption">
              {{ item.thumbnailName }}
            </div>
          </div>
        </div>

        <KeepAlive>
          <Suspense :key="currentCarousel.id">
            <component
              :is="currentCarouselComponent"
              :key="currentCarousel.id"
              v-bind="currentCarouselProps"
              :block-position="blockPosition"
              class="!mb-0 max-w-full"
              :tracking="carouselTrackingData"
            />

            <template #fallback>
              <ProductCardCarouselSkeleton :with-title="false" />
            </template>
          </Suspense>
        </KeepAlive>
      </div>
    </div>
  </TitleCtaContainer>
</template>

<script lang="ts" setup>
import { computed, ref, useCssModule } from 'vue'

import type { EnhancedCarouselProps } from '@backmarket/http-api/src/api-specs-content/models/enhanced-product-carousel'
import type { RecommendationsApiProps } from '@backmarket/http-api/src/api-specs-content/models/recommendations'
import type { RefinedProductsApiProps } from '@backmarket/http-api/src/api-specs-content/models/refined-products'
import ProductCardCarouselSkeleton from '@backmarket/nuxt-layer-recommendation/ProductCardCarouselSkeleton.vue'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButtonBase } from '@ds/components/ButtonBase'

import type { ContentBlockProps } from '../../models/content-block'
import ImageRenderer from '../../shared-components/ImageRenderer/ImageRenderer.vue'
import Recommendations, {
  type RecommendationsProps,
} from '../Recommendations/Recommendations.vue'
import RefinedProducts, {
  type RefinedProductsProps,
} from '../RefinedProducts/RefinedProducts.vue'

import TitleCtaContainer from './TitleCtaContainer.vue'

const props = withDefaults(
  defineProps<EnhancedCarouselProps & ContentBlockProps>(),
  {
    tracking: () => ({}),
  },
)

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

const showThumbnails = computed(() => props.carousels.length >= 2)
const { trackClick } = useTracking()

const logger = useLogger()

const styles = useCssModule()

const currentCarouselIndex = ref(0)

const setCurrentCarousel = (index: number) => {
  currentCarouselIndex.value = index
}

const currentCarousel = computed(
  () => props.carousels[currentCarouselIndex.value].recommendation,
)

const carouselTrackingData = computed(() => {
  return {
    ...props.tracking,
    productRecoComponentPosition: currentCarouselIndex.value + 1,
    productComponent: `EnhancedCarousel_${currentCarousel.value.type}`,
  }
})

function onThumbnailClick(index: number) {
  setCurrentCarousel(index)
  trackClick({
    ...props.tracking,
    title: props.title || '',
    position: index + 1,
    name: props.carousels[index].thumbnailName,
  })
}

const currentCarouselComponent = computed(() => {
  const { type } = currentCarousel.value

  if (type === 'Recommendations') return Recommendations

  if (type === 'RefinedProducts') return RefinedProducts

  logger.error(`[CMS] [EnhancedProductCarousel] Unknown carousel type: ${type}`)

  return null
})

function omitTextAndLinkProps(
  carouselProps: RecommendationsApiProps | RefinedProductsApiProps,
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { title, subtitle, ctaLink, ...rest } = carouselProps

  return rest
}

const currentCarouselProps = computed<
  RecommendationsProps | RefinedProductsProps
>(() => {
  return omitTextAndLinkProps(currentCarousel.value.props)
})
</script>

<style module>
.invisibleScrollBar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
</style>
